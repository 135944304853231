import React from "react";
import { Footer } from "../components/Footer/Footer";
import { HowToWorks } from "../components/HowItWorks";
import { Navbar } from "../components/Navbar/Navbar";
import { Sidebar } from "../components/Sidebar/Sidebar";

export const HowItWorksPage = () => {
  return (
    <section>
      <div className="body-flex">
        <div>
          <header className="bg-light-pink">
            <Navbar />
            <Sidebar />
          </header>
          <section className="container header-padding-top">
            <div className="row py-5">
              <div className="col-lg-10 col-md-11 col-12 mx-auto text-purple text-center header-title">
                Finding your soulmate has never been made easier. Do that in
                just a few steps.
              </div>
            </div>
          </section>
          <HowToWorks />
        </div>
        <Footer className="mt-auto" />
      </div>
    </section>
  );
};

import { Icon } from "@iconify/react";
import React from "react";
import './styles.scss'

export const PriceCards = () => {
  return (
    <div className="price-cards">
      <div className="text-center">
        <p className="name mb-1">BASIC</p>
        <p className="text-grey">Limited access</p>
        <hr />
      </div>
      <div className="text-center">
        <div className="price-container">
          <span className="currency">NGN</span>
          <span className="price">2,500</span>
          <span className="date">/ Month</span>
        </div>
        <p className="text-purple font-bold font24">100% Free </p>
        <button className="btn btn-gradient btn-lg my-4">
          Get Started for Free
        </button>
      </div>
      <ul>
        <li>
          <span className="text-purple">
            <Icon icon="emojione-monotone:white-heavy-check-mark" />
          </span>
          <span className="ms-2 text-grey font-medium">Unlimited Products</span>
        </li>
        <li>
          <span className="text-purple">
            <Icon icon="emojione-monotone:white-heavy-check-mark" />
          </span>
          <span className="ms-2 text-grey font-medium">Robust dashboard analytics.</span>
        </li>
        <li>
          <span className="text-purple">
            <Icon icon="emojione-monotone:white-heavy-check-mark" />
          </span>
          <span className="ms-2 text-grey font-medium">Robust dashboard analytics.</span>
        </li>
        <li>
          <span className="text-purple">
            <Icon icon="emojione-monotone:white-heavy-check-mark" />
          </span>
          <span className="ms-2 text-grey font-medium">Robust dashboard analytics.</span>
        </li>
        <li>
          <span className="text-purple">
            <Icon icon="emojione-monotone:white-heavy-check-mark" />
          </span>
          <span className="ms-2 text-grey font-medium">Robust dashboard analytics.</span>
        </li>
        <li>
          <span className="text-purple">
            <Icon icon="emojione-monotone:white-heavy-check-mark" />
          </span>
          <span className="ms-2 text-grey font-medium">Robust dashboard analytics.</span>
        </li>
        <li>
          <span className="text-purple">
            <Icon icon="emojione-monotone:white-heavy-check-mark" />
          </span>
          <span className="ms-2 text-grey font-medium">Robust dashboard analytics.</span>
        </li>
      </ul>
    </div>
  );
};

import React from "react";
import { DownloadSection } from "../components/DownloadSection";
import { Footer } from "../components/Footer/Footer";
import { Navbar } from "../components/Navbar/Navbar";
import { Sidebar } from "../components/Sidebar/Sidebar";
import about1 from "../assets/img/about1.png";
import about2 from "../assets/img/about2.png";
import about3 from "../assets/img/about3.png";
import about4 from "../assets/img/about4.png";
import about5 from "../assets/img/about5.png";
import about6 from "../assets/img/about6.png";

export const AboutPage = () => {
  return (
    <section>
      <div className="body-flex">
        <div>
          <header className="bg-light-pink">
            <Navbar />
            <Sidebar />
          </header>
          <section className="container header-padding-top">
            <div className="row py-5">
              <div className="col-lg-10 col-md-11 col-12 mx-auto text-purple text-center header-title">
                We help you find Love that is beneficial. Relationships that Fit
                Your Lifestyle
              </div>
            </div>
          </section>
          <section className="container">
            <div className="about-grid">
              <div className="item1">
                <img src={about1} alt="" />
              </div>
              <div className="item2">
                <img src={about2} alt="" />
              </div>
              <div className="item3">
                <img src={about3} alt="" />
              </div>
              <div className="item4">
                <img src={about4} alt="" />
              </div>
              <div className="item5">
                <img src={about5} alt="" />
              </div>
            </div>
          </section>
          <section className="py-5">
            <div className="container">
              <p className="font-bold font33 text-center my-5">
                <span className="text-black">Our</span>{" "}
                <span className="text-purple">Story</span>
              </p>
              <div className="row pt-5">
                <div className="col-lg-5 col-md-5 d-sm-block d-none mb-3 me-lg-5">
                  <img src={about6} alt="" className="w-100" />
                </div>

                <div className="col-lg-6 col-md-6 mb-3">
                  <p
                    className="text-black font33 font-bold mb-2"
                    style={{ lineHeight: "4rem" }}
                  >
                    Built to support people who are looking for unconventional
                    relationships
                  </p>
                  <p className="text-grey mb-4">
                    Our online dating service provides an open-minded community
                    that is a safe place to bring your fantasies to life,
                    instead of dreaming about them. Connect with people who
                    allow you to be yourself based on your shared interests.{" "}
                    <br />
                    As a user of Love With Benefits, you get access to the
                    world’s most secure dating platform. We use KYC (Know Your
                    Customer) technology to keep you and your relationship safe,
                    secure, and private. Our members are fully verified through
                    this technology, ensuring you can trust that who you’re
                    talking to is who they claim to be. This makes our app a
                    worry-free environment for fun, flirty, and exciting dating.
                    Want to send your new love interest or friend a special
                    gift? Love With Benefits allows members like you to send and
                    receive tips that can be cashed out at any point. <br />
                    Our mission is to make sure that you can trust what you
                    expect to get from our platform. We’ve replaced traditional
                    dating and socializing with an innovative environment that
                    makes it fun and free. We take comfort in knowing that we’ve
                    taken every possible step to provide a safe, secure,
                    data-protected, community that reduces any risks associated
                    with other online dating communities. <br />
                  </p>
                </div>
              </div>
            </div>
          </section>
          <DownloadSection
            title="Find The Perfect Love With Benefits"
            subtitle="Join thousands of other  individuals looking for love with benefits by downloading Love With Benefits today! "
          />
          <Footer />
        </div>
      </div>
    </section>
  );
};

import React, { useState } from "react";
import "./sidebar.scope.scss";
import logo from "../../assets/img/logo2.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

export const Sidebar = () => {
  const navigate = useNavigate();
  const [isOpen, setisOpen] = useState(false);
  const sidebarToggle = () => {
    setisOpen(!isOpen);
  };

  const auth = (auth) => {
    navigate("/auth", { state: auth });
  };
  return (
    <>
      <div
        className={
          isOpen ? "overlay-isOpen sidebar-overlay" : "sidebar-overlay"
        }
      >
        <div className="close" onClick={sidebarToggle}>
          <Icon icon="clarity:times-line" />
        </div>
      </div>

      <div
        className={
          isOpen
            ? "navbar-toggler menu-icon d-none"
            : "navbar-toggler menu-icon"
        }
        onClick={sidebarToggle}
      >
        <Icon icon="grommet-icons:menu" />
      </div>
      <div className="nav-logo-2">
        <NavLink to="/">
          <img src={logo} alt="" />
        </NavLink>
      </div>
      <section className={isOpen ? "sidebar-isOpen sidebar" : "sidebar"}>
        <div className="sidebar-wrapper">
          <div className="side-logo">
            <Link to="/home">
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="mt-5">
            <NavLink
              className="side-item"
              onClick={sidebarToggle}
              to="/home"
              activeClassName="active"
            >
              Home
            </NavLink>

            <NavLink
              className="side-item"
              onClick={sidebarToggle}
              to="/about"
              activeClassName="active"
            >
              About
            </NavLink>

            <NavLink
              className="side-item"
              onClick={sidebarToggle}
              to="/how-it-works"
              activeClassName="active"
            >
              How It Works
            </NavLink>
            {/* <NavLink
              className="side-item"
              onClick={sidebarToggle}
              to="/pricing"
              activeClassName="active"
            >
              Pricing
            </NavLink>
            <NavLink
              className="side-item"
              onClick={sidebarToggle}
              to="/faq"
              activeClassName="active"
            >
              FAQ
            </NavLink> */}

            <div className="side-item" onClick={sidebarToggle}>
              <button className="btn btn-gradient px-4">Get App</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

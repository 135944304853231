import React from "react";
import { Accordion } from "react-bootstrap";
import { Footer } from "../components/Footer/Footer";
import { Navbar } from "../components/Navbar/Navbar";
import { Sidebar } from "../components/Sidebar/Sidebar";

export const FAQPage = () => {
  return (
    <section>
      <div className="body-flex">
        <div>
          <header className="bg-light-pink">
            <Navbar />
            <Sidebar />
          </header>
          <section className="container header-padding-top text-purple text-center header-title">
            FAQs
          </section>
          <section className="container py-5 faq-wrapper">
            <div className='mb-5'>
            <p className="font-bold text-black font33">General</p>
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What does the subscription include?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Lectus vulputatehjjjuhhhhhh euismod sit risus ac dignissim
                  dolor sed. Commodo arcu malesuada nonghh metus eu, amet,
                  tincidunt. Neque orci odio suspendisse cras lectus eratgghh
                  phasellushhh. Condimentum cursus varius dui hac auctor lorem
                  eget facilisis duis. Risus nec vitae arcu semper venenatis
                  fringilla nisl pellentesque. Sedyuuuiiiii ullamcorper
                  phasellus pellentesque cursus scelerisque id ac. Sapien
                  mighjjk sagittis, quis quam arcuhhhh dapibus elementumyuuii.
                  Pellentesque facilisishjjkkj faucibus tortor morbihhhhhhhh
                  commodo mauris. Sed odio diam vel aliquet urna cras
                  nhhhhhhhhhhhhhhhhhhhhh
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Can i report a user that i do not feel comfortable with?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            </div>
            <div className='mb-5'>
            <p className="font-bold text-black font33">Billing</p>
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What does the subscription include?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Lectus vulputatehjjjuhhhhhh euismod sit risus ac dignissim
                  dolor sed. Commodo arcu malesuada nonghh metus eu, amet,
                  tincidunt. Neque orci odio suspendisse cras lectus eratgghh
                  phasellushhh. Condimentum cursus varius dui hac auctor lorem
                  eget facilisis duis. Risus nec vitae arcu semper venenatis
                  fringilla nisl pellentesque. Sedyuuuiiiii ullamcorper
                  phasellus pellentesque cursus scelerisque id ac. Sapien
                  mighjjk sagittis, quis quam arcuhhhh dapibus elementumyuuii.
                  Pellentesque facilisishjjkkj faucibus tortor morbihhhhhhhh
                  commodo mauris. Sed odio diam vel aliquet urna cras
                  nhhhhhhhhhhhhhhhhhhhhh
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Can i report a user that i do not feel comfortable with?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            </div>
            <div className='mb-5'>
            <p className="font-bold text-black font33">Support</p>
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What does the subscription include?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Lectus vulputatehjjjuhhhhhh euismod sit risus ac dignissim
                  dolor sed. Commodo arcu malesuada nonghh metus eu, amet,
                  tincidunt. Neque orci odio suspendisse cras lectus eratgghh
                  phasellushhh. Condimentum cursus varius dui hac auctor lorem
                  eget facilisis duis. Risus nec vitae arcu semper venenatis
                  fringilla nisl pellentesque. Sedyuuuiiiii ullamcorper
                  phasellus pellentesque cursus scelerisque id ac. Sapien
                  mighjjk sagittis, quis quam arcuhhhh dapibus elementumyuuii.
                  Pellentesque facilisishjjkkj faucibus tortor morbihhhhhhhh
                  commodo mauris. Sed odio diam vel aliquet urna cras
                  nhhhhhhhhhhhhhhhhhhhhh
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Can i report a user that i do not feel comfortable with?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </section>
  );
};

import { Icon } from "@iconify/react";
import React from "react";

export const DownloadSection = ({ title, subtitle }) => {
  return (
    <section className="bg-gradient py-5">
      <div className="container text-white py-5">
        <p className="font33 font-bold text-center">{title}</p>
        <div className="row">
          <div className="mx-auto col-lg-8 col-md-10 col-12">
            <p className="font-medium text-center font18">{subtitle}</p>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button className="btn btn-download btn-download-white me-3">
            <div className="icon">
              <Icon icon="ant-design:apple-filled" />
            </div>
            <div>
              <p className="small">Download on the</p>
              <p className="big">App Store</p>
            </div>
          </button>
          <button className="btn btn-download btn-download-white">
            <div className="icon">
              <Icon icon="flat-ui:google" />
            </div>
            <div>
              <p className="small">Get it on</p>
              <p className="big">Google play</p>
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

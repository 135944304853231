import { Icon } from "@iconify/react";
import React from "react";
import { Footer } from "../components/Footer/Footer";
import { Navbar } from "../components/Navbar/Navbar";
import { Sidebar } from "../components/Sidebar/Sidebar";
import home3 from "../assets/img/home3.png";
import home2 from "../assets/img/home2.png";
import home1 from "../assets/img/home1.png";
import easy from "../assets/img/easy.png";
import { HowToWorks } from "../components/HowItWorks";
import { DownloadSection } from "../components/DownloadSection";

export const HomePage = () => {
  return (
    <section className="home-wrapper">
      <div className="body-flex">
        <div>
          <header>
            <Navbar />
            <Sidebar />
            <div className="container header-padding-top">
              <div className="row">
                <div className="mt-lg-5 col-lg-6 col-md-12 order-lg-1 order-2 mb-2">
                  <p className="font-bold header-title mb-0">
                    Join A Secure. Private. Safe Place to Find Love With
                    Benefits
                  </p>
                  <p className="text-grey mb-5">
                    You know your soulmate won’t just fall in your lap. You have
                    to make an effort to find them. Love With Benefits is the
                    first stop on your journey to find the perfect partner.
                    Someone who checks all the boxes and offers you the fun,
                    exciting, and loving relationship you crave. You don’t have
                    to spend years chasing down someone who syncs up with what
                    you want. All you have to do is download Love With Benefits
                    and start matching with the right people! We’ve helped
                    thousands of couples find each other, and we can help you
                    find your ideal partner, too!
                  </p>

                  <div className="d-flex">
                    <button className="btn btn-download btn-download-black me-3">
                      <div className="icon">
                        <Icon icon="ant-design:apple-filled" />
                      </div>
                      <div>
                        <p className="small">Download on the</p>
                        <p className="big">App Store</p>
                      </div>
                    </button>
                    <button className="btn btn-download btn-download-black">
                      <div className="icon">
                        <Icon icon="flat-ui:google" />
                      </div>
                      <div>
                        <p className="small">Get it on</p>
                        <p className="big">Google play</p>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="my-auto col-lg-6 col-md-8 order-lg-2 order-1 mb-2">
                  <img src={home1} alt="" className="w-100" />
                </div>
              </div>
            </div>
          </header>
          <section className="py-5">
            <div className="container">
              <div className="d-flex justify-content-around total-numbers">
                <div>
                  <p className="big">20K</p>
                  <p className="small">Matches made</p>
                </div>
                <div>
                  <p className="big">10K</p>
                  <p className="small">Signups daily</p>
                </div>
                <div>
                  <p className="big">1M+</p>
                  <p className="small">Users worldwide</p>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5">
            <div className="container">
              <p className="font-bold font33 text-center mb-5">
                <span className="text-black">About</span>{" "}
                <span className="text-purple">Us</span>
              </p>
              <div className="row">
                <div className="col-lg-6 col-md-6 d-sm-block d-none mb-3">
                  <img src={home2} alt="" className="w-100 br-8" />
                </div>

                <div className="col-lg-6 col-md-6 mb-3">
                  <p className="text-purple font33 font-bold mb-2">
                    Love With Benefits
                  </p>
                  <p className="text-grey mb-4">
                    Love With Benefits was launched in 2021 to help elite
                    singles find each other in a secure, private, and safe
                    environment.
                    <br /> <br />
                    We use Know Your Customer technologies to verify each user
                    profile that signs up for our service to give you peace of
                    mind. Your privacy and security are our number one concern.
                  </p>
                  <button className="btn btn-gradient btn-lg w-70 w-sm-90">
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </section>
          <HowToWorks />
          <section className="py-5">
            <div className="container">
              <p className="font-bold font33 text-center mb-5">
                <span className="text-black">You’ll Fall In Love with </span>{" "}
                <span className="text-purple"> Love With Benefits!</span>
              </p>
              <div className="row">
                <div className="col-md-6 col-12 mb-4">
                  <div className="bg-white box-shadow p-4 mx-4 d-flex br-8">
                    <div className="me-2 text-purple font33 line-height-1">
                      <Icon icon="ic:outline-verified-user" />
                    </div>
                    <div>
                      <p className="text-black font-bold font18 mb-1">
                        No Catfishing
                      </p>
                      <p className="text-grey font-medium">
                        We use industry-leading technology to guarantee that who
                        you’re talking to is who they say they are. No more
                        catfishing!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <div className="bg-white box-shadow p-4 mx-4 d-flex br-8">
                    <div className="me-2 text-purple font33 line-height-1">
                      <img
                        src={easy}
                        alt=""
                        style={{
                          width: "4rem",
                          height: "4rem",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div>
                      <p className="text-black font-bold font18 mb-1">
                        Easy To Use
                      </p>
                      <p className="text-grey font-medium">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Lectus vulputatehjjju euismod sit risus ac digniss dolor
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <div className="bg-white box-shadow p-4 mx-4 d-flex br-8">
                    <div className="me-2 text-purple font33 line-height-1">
                      <Icon icon="bi:gift" />
                    </div>
                    <div>
                      <p className="text-black font-bold font18 mb-1">
                        Gifting
                      </p>
                      <p className="text-grey font-medium">
                        Send gifts to your matches easily through the app. Our
                        technology is integrated with the right features to
                        ensure you can give your match anything you want.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <div className="bg-white box-shadow p-4 mx-4 d-flex br-8">
                    <div className="me-2 text-purple font33 line-height-1">
                      <Icon icon="ion:notifications-outline" />
                    </div>
                    <div>
                      <p className="text-black font-bold font18 mb-1">
                        Reliable Notifications
                      </p>
                      <p className="text-grey font-medium">
                        Get notified quickly and easily when your match messages
                        you. Customize your notifications to connect with your
                        potential soul mate on your own terms.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <div className="bg-white box-shadow p-4 mx-4 d-flex br-8">
                    <div className="me-2 text-purple font33 line-height-1">
                      <Icon icon="eva:message-circle-outline" />
                    </div>
                    <div>
                      <p className="text-black font-bold font18 mb-1">
                        Seamless Messaging
                      </p>
                      <p className="text-grey font-medium">
                        Message your matches simply and easily with our
                        messaging features. Chat about your interests, set up a
                        date, or simply share images all through our app.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 mb-4">
                  <div className="bg-white box-shadow p-4 mx-4 d-flex br-8">
                    <div className="me-2 text-purple font33 line-height-1">
                      <Icon icon="akar-icons:heart" />
                    </div>
                    <div>
                      <p className="text-black font-bold font18 mb-1">
                        Non judgemental Space
                      </p>
                      <p className="text-grey font-medium">
                        Our app is flexible, and our community is non
                        judgmental. You can find the people you’re interested in
                        without fear of being judged for who you are.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5">
            <div className="container">
              <p className="font-bold font33 text-center mb-5">
                <span className="text-black">What Love With Benefits </span>{" "}
                <span className="text-purple">Users Have to Say</span>
              </p>
              <div className="row">
                <div className="col-lg-6 col-md-6 my-auto">
                  <p
                    className="font24 text-grey mb-1"
                    style={{ lineHeight: 2.2 }}
                  >
                    <span className="text-black">“</span> Love With Benefits is
                    the fun, easy-to-use dating app if you want to have a good
                    time…right now Thank you for making it possible for me to
                    meet my soulmate.<span className="text-black">”</span>
                  </p>
                  <p className="font24 text-purple" style={{ lineHeight: 2.2 }}>
                    Sam and Gabrielle
                  </p>
                  <div className="d-flex">
                    <div className="me-2 font-bold text-black">Next</div>
                    <div className="icon-btn">
                      <Icon icon="fontisto:angle-right" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 d-sm-block d-none">
                  <img
                    src={home3}
                    alt=""
                    className="w-100 br-8"
                    style={{ height: "44rem", objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>
          </section>
          <DownloadSection
            title="Download Love With Benefits & Start Loving Your Love Life Again!"
            subtitle="Enjoy a safe, secure, private, fun, and catfish-free dating site where all elite singles are welcome!"
          />
        </div>
        <Footer className="mt-auto" />
      </div>
    </section>
  );
};

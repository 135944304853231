import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo2.svg";
import "./navbar.scss";

export const Navbar = () => {
  const navigate = useNavigate();
  window.onscroll = () => scrollFunction();

  const scrollFunction = () => {
    if (window.scrollY > 150) {
      document.querySelector(".navbar")?.classList.add("fixed-top");
    } else {
      document.querySelector(".navbar")?.classList.remove("fixed-top");
    }
  };

  // const auth = (auth) => {
  //   navigate("/auth", { state: auth });
  // };
  return (
    <div className="navbar">
      <div className="container d-flex justify-content-between align-items-center w-100">
        <div className="nav-logo">
          <NavLink to="/">
            <img src={logo} alt="" />
          </NavLink>
        </div>
        <div className="d-flex align-items-center">
          <NavLink className="navlink" to="/home" activeClassName="active">
            Home
          </NavLink>

          <NavLink className="navlink" to="/about" activeClassName="active">
            About
          </NavLink>
          <NavLink
            className="navlink"
            to="/how-it-works"
            activeClassName="active"
          >
            How It Works
          </NavLink>
          {/* <NavLink className="navlink" to="/pricing" activeClassName="active">
            Pricing
          </NavLink>

          <NavLink className="navlink" to="/faq" activeClassName="active">
            FAQ
          </NavLink> */}

          <div className="navlink btn-link">
            <button className="btn btn-gradient btn-md">Get App</button>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import "./styles.scss";
import line1 from "../../assets/img/line1.png";
import line2 from "../../assets/img/line2.png";

export const HowToWorks = () => {
  return (
    <section className="how-it-works-wrapper">
      <section className="py-5">
        <div className="container">
          <p className="font-bold font33 text-center mb-5">
            <span className="text-black">How It</span>
            <span className="text-purple"> Works</span>
          </p>
          <div className="row">
            <div className="col-lg-7 col-md-8 mb-3">
              <div className="d-flex align-items-center">
                <div className="me-3 number">1</div>
                <div>
                  <p className="text-gradient font-bold mb-2 font24">
                    Download App
                  </p>
                  <p>
                    Love With Benefits is available on iTunes and in the Play
                    store. You can also access it via your browser. It’s simple
                    and easy!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12"></div>
            <div className="col-lg-5 col-md-7 col-9 mb-3 mx-auto">
              <img src={line1} alt="" className="w-100" />
            </div>
            <div className="col-12"></div>
            <div className="col-lg-7 col-md-8 mb-3 ms-auto">
              <div className="d-flex align-items-center">
                <div className="me-3 number">2</div>
                <div>
                  <p className="text-gradient font-bold mb-2 font24">
                    Create Account
                  </p>
                  <p>
                    Get verified through our industry-leading technology and set
                    up your account. Share your interests, image, and location
                    and we’ll do the rest!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12"></div>
            <div className="col-lg-5 col-md-7 col-9 mb-3 mx-auto">
              <img src={line2} alt="" className="w-100" />
            </div>
            <div className="col-12"></div>
            <div className="col-lg-7 col-md-8 mb-3">
              <div className="d-flex align-items-center">
                <div className="me-3 number">3</div>
                <div>
                  <p className="text-gradient font-bold mb-2 font24">
                    Start Your Search
                  </p>
                  <p>
                    Once your account is set up it’s time to find your match!
                    Simply start swiping to find friends who match your
                    interests.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12"></div>
            <div className="col-lg-5 col-md-7 col-9 mb-3 mx-auto">
              <img src={line1} alt="" className="w-100" />
            </div>
            <div className="col-12"></div>
            <div className="col-lg-7 col-md-8 mb-3 ms-auto">
              <div className="d-flex align-items-center">
                <div className="me-3 number">4</div>
                <div>
                  <p className="text-gradient font-bold mb-2 font24">
                    Connect with Friends
                  </p>

                  <p>
                    Through our app, you can send messages and gifts to your
                    matches. We make it fun and safe to communicate with people
                    you’re attracted to.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

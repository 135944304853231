import React from "react";
import { Accordion } from "react-bootstrap";
import { Footer } from "../components/Footer/Footer";
import { Navbar } from "../components/Navbar/Navbar";
import { Sidebar } from "../components/Sidebar/Sidebar";
import faq from "../assets/img/faq.png";
import { PriceCards } from "../components/PriceCards";

export const PricingPage = () => {
  return (
    <section className="pricing-wrapper">
      <div className="body-flex">
        <div>
          <header>
            <Navbar />
            <Sidebar />
            <section className="container text-center header-padding-top">
              <p className="text-purple header-title mt-5">
                Enjoy More With Love With Benefits
              </p>
              <p className="text-grey font-medium">
                Choose the plan that suits you
              </p>
              <div className="plan-dropdown-wrapper">
                <div className="me-3 d-flex">
                  <div className="left">Monthly</div>
                  <div className="right">Annually - Save 20%</div>
                </div>
                <div className="dropdown">
                  <select>
                    <option value="NGN">NGN</option>
                    <option value="USD">USD</option>
                    <option value="GBP">GBP</option>
                    <option value="KES">KES</option>
                    <option value="ZAR">ZAR</option>
                    <option value="TZS">TZS</option>
                    <option value="UGX">UGX</option>
                  </select>
                </div>
              </div>
            </section>
          </header>
          <section className="container price-section">
            <div className="row">
              <div className="col-lg-5 col-md-6 col-12 mb-2 ms-auto">
                <PriceCards />
              </div>
              <div className="col-lg-5 col-md-6 col-12 mb-2 me-auto">
                <PriceCards />
              </div>
            </div>
          </section>
          <section className="container py-5">
            <section className="py-5 faq-wrapper row">
              <div className="col-lg-4 col-md-5 col-10 my-auto mb-3">
                <p
                  className="font-bold font40 text-black"
                  style={{ lineHeight: "5rem" }}
                >
                  Frequently Asked Questions
                </p>
                <img src={faq} alt="" className="w-100" />
              </div>
              <div className="col-lg-7 col-md-12 col-12 mb-3 ms-auto">
                <div className="mb-3 faq-wrapper">
                  <Accordion defaultActiveKey="0" flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        What does the subscription include?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Lectus vulputatehjjjuhhhhhh euismod sit risus ac
                        dignissim dolor sed. Commodo arcu malesuada nonghh metus
                        eu, amet, tincidunt. Neque orci odio suspendisse cras
                        lectus eratgghh phasellushhh. Condimentum cursus varius
                        dui hac auctor lorem eget facilisis duis. Risus nec
                        vitae arcu semper venenatis fringilla nisl pellentesque.
                        Sedyuuuiiiii ullamcorper phasellus pellentesque cursus
                        scelerisque id ac. Sapien mighjjk sagittis, quis quam
                        arcuhhhh dapibus elementumyuuii. Pellentesque
                        facilisishjjkkj faucibus tortor morbihhhhhhhh commodo
                        mauris. Sed odio diam vel aliquet urna cras
                        nhhhhhhhhhhhhhhhhhhhhh
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Can i report a user that i do not feel comfortable with?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </section>
          </section>
        </div>
        <Footer />
      </div>
    </section>
  );
};
